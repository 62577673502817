export const boundary = 'boundary';
export const polygon = 'polygon';
export const geoIds = 'geoIds';
export const geoId = 'geoId';
export const aoi = 'aoi';
export const layers = 'layers';
export const scripts = 'scripts';
export const map = 'map';
export const mainMenu = 'mainmenu';
export const statisticsOpen = 'statisticsOpen';
export const feature = 'feature';
export const embed = 'embed'; // Set explicitly on the URL to sign-in automatically as anonymous users
export const lang = 'lang';
export const fetchJson = 'fetchJson';
