import { createSelector } from 'reselect';

export const getOrderedAreas = createSelector(
  (state) => state.areas.get('areaList'),
  (state) => state.app.get('selectedLanguage'),
  (areas, selectedLanguage) => {
    if (areas) {
      return areas.sort((a, b) => a.get('name').get(selectedLanguage).localeCompare(b.get('name').get(selectedLanguage)));
    }
    return null;
  },
);

export const getSelectedAreaId = createSelector(
  (state) => state.areas.get('selectedAreaId'),
  (selectedAreaId) => selectedAreaId,
);

export const getSelectedAoi = createSelector(
  (state) => state.areas.get('areas'),
  (state) => state.areas.get('selectedAreaId'),
  (areas, selectedAreaId) => areas.get(selectedAreaId),
);

export const isAreasLoading = createSelector(
  (state) => state.areas.get('loading'),
  (isAreasLoading) => isAreasLoading,
);
