import { createSelector } from 'reselect';

export const getClickedBoundary = createSelector(
  (state) => state.map.get('clickedBoundary'),
  (clickedBoundary) => clickedBoundary,
);

export const getViewPort = createSelector(
  (state) => state.map.get('viewPort'),
  (boundingBox) => boundingBox,
);

export const getDrawingMode = createSelector(
  (state) => state.map.get('drawingMode'),
  (drawingMode) => drawingMode,
);

export const getVisibleLayers = createSelector(
  (state) => state.map.get('visibleLayers'),
  (visibleLayers) => visibleLayers,
);

export const getLeftSplitLayer = createSelector(
  (state) => state.map.get('leftSplitLayer'),
  (leftSplitLayer) => leftSplitLayer,
);

export const getRightSplitLayer = createSelector(
  (state) => state.map.get('rightSplitLayer'),
  (rightSplitLayer) => rightSplitLayer,
);
