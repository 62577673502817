import { fromJS } from 'immutable';

import * as actionTypes from 'store/actions/actionTypes';
import Analytics from 'utils/analytics';

const analytics = new Analytics();

const initialState = fromJS({
  drawingMode: false,
  visibleLayers: [],
  leftSplitLayer: null,
  rightSplitLayer: null,
  clickedBoundary: undefined,
  viewPort: null,
});

const enableDrawingMode = (state, action) => state.merge({
  drawingMode: action.enable,
});

const boundarySelected = (state, options) => {
  const clickedBoundary = fromJS({
    geoJson: options.geoJson,
  });
  return state.merge({ clickedBoundary });
};

const toggleLayer = (state, options) => {
  const index = state.get('visibleLayers').indexOf(options.layerId);
  if (index < 0) {
    if (analytics) analytics.logEvent('Layer', options.layerId);
    return state.merge({
      visibleLayers: state.get('visibleLayers').push(options.layerId),
    });
  }
  return state.merge({
    visibleLayers: state.get('visibleLayers').delete(index),
  });
};

const setViewPort = (state, action) => state.merge(
  { viewPort: fromJS(action.boundingBox) },
);

const setLeftSplitLayer = (state, action) => state.merge(
  { leftSplitLayer: action.layerId }
)

const setRightSplitLayer = (state, action) => state.merge(
  { rightSplitLayer: action.layerId }
)

const mapreducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENABLE_DRAWING_MODE:
      return enableDrawingMode(state, action);
    case actionTypes.BOUNDARY_SELECT:
      return boundarySelected(state, action);
    case actionTypes.TOGGLE_MAP_LAYER:
      return toggleLayer(state, action);
    case actionTypes.SET_VIEWPORT:
      return setViewPort(state, action);
    case actionTypes.SET_LEFT_SPLIT_LAYER:
      return setLeftSplitLayer(state, action);
    case actionTypes.SET_RIGHT_SPLIT_LAYER:
      return setRightSplitLayer(state, action);
    case actionTypes.CLEAR_MAP:
      return initialState;
    default:
      return state;
  }
};

export default mapreducer;
